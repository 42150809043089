<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row v-if="!loadingData">
                    <b-col class="text-center">
                        <p>{{ (currentLocale === 'en') ? 'Loading! Please wait...' : 'লোড হচ্ছে! অনুগ্রহপূর্বক অপেক্ষা করুন...' }}</p>
                        <h1><i class="fas fa-spinner fa-pulse"></i></h1>
                    </b-col>
                </b-row>
                <b-row v-if="userList.length > 0">
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col xl="12" sm="12">
                                <b-row>
                                    <b-col md=12>
                                          <b-table-simple striped bordered small hover>
                                                <b-tbody>
                                                    <b-tr>
                                                        <b-th colspan="4"><h5 class="text-white text-center contentTitle">{{ $t('research_manage.project_info') }}</h5></b-th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-th class="text-left">{{$t('external_research.project_id')}}</b-th>
                                                        <b-td class="text-left">{{ proposal.project.proposal_auto_id }}</b-td>
                                                        <b-th class="text-left">{{$t('external_research.project_title')}}</b-th>
                                                        <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.project.project_title : proposal.project.project_title_bn }}</b-td>
                                                    </b-tr>
                                                     <b-tr>
                                                        <b-th class="text-left">{{$t('external_research.thematic_area')}}</b-th>
                                                        <b-td colspan="3" class="text-left">{{ getThematicAreaList(proposal.thematic_area_id) }}</b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                            <b-table-simple striped bordered small hover>
                                              <thead>
                                                    <b-tr>
                                                        <b-th colspan="7"><h5 class="text-white text-center contentTitle">{{ $t('research_manage.team_member') + ' ' + $t('globalTrans.details') }}</h5></b-th>
                                                    </b-tr>
                                                   <b-tr>
                                                        <b-td colspan="7" class="text-center">{{ getOrgName(proposal.monitoring_team_member.org_id) }} <br/> {{ getFiscalYearList(proposal.monitoring_team_member.fiscal_year_id) }} <br/> {{ getMonitoringTeam(proposal.monitoring_team_member.team_id) }}</b-td>
                                                    </b-tr>
                                                    <tr>
                                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                        <th class="text-center">{{ $t('globalTrans.organization' )}}</th>
                                                        <th class="text-center">{{ $t('research_manage.designation_en') }}</th>
                                                        <th class="text-center">{{ $t('research_manage.team_member') }}</th>
                                                        <th class="text-center">{{ $t('research_manage.role_name') }}</th>
                                                        <th class="text-center">{{ $t('globalUserData.mobile_no') }}</th>
                                                    </tr>
                                                </thead>
                                                <b-tbody>
                                                    <b-tr v-for="(item, index) in proposal.monitoring_team_member.members" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                        <b-td class="text-center">{{ getOrgName(item.org_id) }}</b-td>
                                                        <b-td class="text-center">{{ getDesignationName(item.designation_id) }}</b-td>
                                                        <b-td class="text-center">{{ getTeamMember(item.user_id) }}</b-td>
                                                        <b-td class="text-center">{{ getRoleName(item.master_role_id) }}</b-td>
                                                        <b-td class="text-center">{{ item.mobile_no | mobileNumber }}</b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                            <b-table-simple striped bordered small hover>
                                              <thead>
                                                    <b-tr>
                                                        <b-th colspan="4"><h5 class="text-white text-center contentTitle">{{ $t('external_research.monitoring_schedule') + ' ' + $t('sidebar.information') }}</h5></b-th>
                                                    </b-tr>
                                                    <tr>
                                                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                        <th class="text-center">{{ $t('globalTrans.date' )}}</th>
                                                    </tr>
                                                </thead>
                                                <b-tbody>
                                                    <b-tr v-for="(item, index) in proposal.schedules" :key="index">
                                                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                        <b-td class="text-center">{{ item.date | dateFormat }}</b-td>
                                                    </b-tr>
                                                </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringScheduleProposalShowApi } from '../../../api/routes'
export default {
    props: ['id'],
    data () {
        return {
            loadingData: false,
            proposal: {},
            userList: []
        }
    },
    created () {
        if (this.id) {
            this.loadData()
        }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      }
    },
    methods: {
        // getOrgName (Id) {
        // const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === Id)
        // if (this.$i18n.locale === 'en') {
        //     return Obj.text_en
        //     } else {
        //         return Obj.text_bn
        //     }
        // },
        getOrgName (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === Id)
        if (this.$i18n.locale === 'en') {
                return Obj.text_en
                } else {
                return Obj.text_bn
                }
        },
        getOrganization (orgId) {
        const orgName = this.$store.state.orgList.find(item => item.value === orgId)
        if (orgName === undefined) return ''
        return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
        },
        getFiscalYearList (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
         getDesignationName (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
        getThematicAreaList (Id) {
        const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
        getMonitoringTeam (monitoringId) {
        const team = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.monitoringTeamList.find(item => item.value === monitoringId)
        if (team === undefined) return ''
        return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
        },
        getResearchType (monitoringId) {
        const team = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.researchTypeList.find(item => item.value === monitoringId)
        if (team === undefined) return ''
        return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
        },
        getRoleName (roleId) {
        const role = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.roleInformationList.find(item => item.value === roleId)
        if (role === undefined) return ''
        return this.$i18n.locale === 'bn' ? role.text_bn : role.text_en
        },
        getTeamMember (userId) {
        const user = this.userList.find(item => item.value === parseInt(userId))
        if (user === undefined) return ''
        return this.$i18n.locale === 'bn' ? user.text_bn : user.text_en
        },
        loadData () {
            RestApi.getData(agriResearchServiceBaseUrl, `${monitoringScheduleProposalShowApi}/${this.id}`).then(response => {
                if (response.success) {
                     this.proposal = response.data
                     this.userList = response.user_details
                     this.loadingData = true
                }
                // this.loadingData = false
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .contentTitle {
      font-size: 15px; background-color: #005B5B; padding: 1px
  }
</style>
