<template>
  <b-container fluid>

    <!-- Search Form -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.monitoring_schedule') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col sm="12" md="6" lg="6">
              <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
              >
                <b-form-select
                  plain
                  v-model="search.thematic_area_id"
                  :options="thematicAreaList"
                  id="thematic_area_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="6" lg="6">
              <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.project_id')"
                label-for="project_id"
              >
                <b-form-input
                  plain
                  v-model="search.project_id"
                  id="project_id"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="6" lg="6">
              <b-button type="button" variant="primary" @click="searchData">
                {{ $t('globalTrans.search')}}
              </b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <!-- /Search Form -->

    <!-- Table -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.monitoring_schedule') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12" md="12" lg="12" class="table-responsive">
              <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" show-empty>
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)">
                    <i class="ri-eye-fill m-0"></i>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                @input="searchData"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <!-- /Table -->

    <!-- Details -->
    <b-modal id="modal-5" size="xl" :title="$t('globalTrans.details')"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringScheduleProposalListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: { Details },
  data () {
    return {
      rows: [],
      item: '',
      search: {
        thematic_area_id: 0,
        project_id: ''
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    thematicAreaList: function () {
      return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('external_research.project_id'), class: 'text-left' },
          { label: this.$t('external_research.project_title'), class: 'text-left' },
          { label: this.$t('external_research.thematic_area'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'project.proposal_auto_id' },
          { key: 'project.project_title_bn' },
          { key: 'thematic_area_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'project.proposal_auto_id' },
          { key: 'project.project_title' },
          { key: 'thematic_area_name_en' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    view (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, monitoringScheduleProposalListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      return data.map(item => {
        // Relational
        const thematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(obj => obj.value === item.thematic_area_id)

        // Evaluate
        const evaluationData = {
          thematic_area_name_en: thematicArea !== undefined ? thematicArea.text_en : null,
          thematic_area_name_bn: thematicArea !== undefined ? thematicArea.text_bn : null
        }

        // Assign
        return Object.assign({}, item, evaluationData)
      })
    }
  }
}
</script>
